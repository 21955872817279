import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"

const FeedbackPage = () => (
  <Layout bodyClassName='feedback'>
    <SEO
      title="Feedback"
      meta={[
        {
          property: `robots`,
          content: `noindex`,
        },
      ]}
    />

    <div className="page feedback">
      <div className="heroStripe">
        <div className="container">
          <h1 className="pageTitle">How can we be better?</h1>
    
          <form id="form4" name="form4" className="wufoo topLabel page" acceptCharset="UTF-8" autoComplete="off" encType="multipart/form-data" method="post" noValidate
                action="https://nscramer.wufoo.com/forms/rt4j2bk0ia5rp2/#public">

            <div>
              <textarea id="Field1" name="Field1" className="field textarea medium" autoFocus spellCheck="true" rows="10" tabIndex="1" onKeyUp="" placeholder="All feedback is good feedback..." required></textarea>
            </div>

            <input id="saveForm" name="saveForm" className="btTxt submit btn btn--primary btn--block" type="submit" value="Send" />

            <input type="hidden" id="idstamp" name="idstamp" value="nUbWEu3Y5WrYqPj7Hr6HbNgbfB9CcsvdXhycQcSx6CU=" />
          </form>
        </div>
      </div>
    </div>
  </Layout>
);

export default FeedbackPage
